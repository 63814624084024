import React from "react";
import rocketBoxIcon from "../icons/rocketBox.svg";

const LandingContainer = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={rocketBoxIcon} className="App-logo" alt="logo" />
        <p>It's Alive!!!</p>
      </header>
    </div>
  );
};
export default LandingContainer;
