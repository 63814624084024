import React, { Component } from "react";

import LandingContainer from "./components/LandingContainer.js";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <LandingContainer/>
      </div>
    )
  }
}

export default App;
